import { Match, Show, Switch, createEffect, createMemo, untrack } from "solid-js";
import { createProjectSubmissionInputs } from "./form";
import { get_submission_details, get_submission_status, update_project_submission } from "./apis";
import { solidstate } from ":shared/utils/state-manager";
import { global } from ":global";
import { PrimaryButton } from ":src/components/form";
import { Loader2 } from ":src/components/loaders";
import { downloadFile } from ":shared/helpers/methods";

export default function ProjectSubmission(props) {
  // $image_max().values?.value is image size maximum in MB
  const $image_max = global.store.hooks.platform.useSettings("image_max");
  const $pdf_max = global.store.hooks.platform.useSettings("pdf_max");
  const $settings = global.store.hooks.platform.useSettings("submission");
  const FormInputs = createProjectSubmissionInputs({
    image_max: $image_max().values?.value,
    pdf_max: $pdf_max().values?.value,
  });
  const $submission_status = solidstate.createAsync(get_submission_status);
  const $submission_details = solidstate.createAsync(get_submission_details);
  const $can_update = solidstate.create(false);
  const $loading = solidstate.create(false);
  FormInputs.Track?.control?.markDisabled(true);

  const char_limit = 500;
  const calculateCharCount = (inputField) => {
    const user_chars = inputField.control.value;
    return `${!user_chars ? 0 : user_chars.length}/${char_limit}`;
  };

  const $ProblemStatementChars = createMemo(() => calculateCharCount(FormInputs.ProblemStatement));
  const $IdeationChars = createMemo(() => calculateCharCount(FormInputs.Ideation));
  const $SolutionFeasibilityChars = createMemo(() => calculateCharCount(FormInputs.Feasibility));
  const $ProjectImpactChars = createMemo(() => calculateCharCount(FormInputs.Impact));
  const $ResearchProcessChars = createMemo(() => calculateCharCount(FormInputs.ResearchProcess));
  const $ApplicableSolutionChars = createMemo(() => calculateCharCount(FormInputs.ApplicableSolution));
  const $BusinessModelChars = createMemo(() => calculateCharCount(FormInputs.BusinessModel));

  createEffect(() => {
    if ($submission_status.state.success) {
      if ($submission_status.unwrap.value?.submission) {
        $can_update.set(true);
        if ($submission_details.state.success) {
          if (
            $submission_details?.unwrap?.value?.team === null ||
            $submission_details?.unwrap?.value?.team === undefined
          ) {
            return;
          }
          FormInputs.Actions.update($submission_details?.unwrap?.value?.team);
          console.log("submission_details.unwrap.value?.team:: ", $submission_details.unwrap.value?.team);
        }
      }
    }
  });

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    $loading.set(true);
    try {
      const values = FormInputs.Actions.getValuesSnakecase();

      const { presentation, project_cover, project_images, ...filteredValues } = values;
      const payload = {
        ...filteredValues,
        project_images: FormInputs?.ProjectImages?.control?.value?.[0],
        presentation: FormInputs?.Presentation?.control?.value?.[0],
        project_cover: FormInputs?.ProjectCover?.control?.value?.[0],
      };
      console.log("values:: ", values);
      await update_project_submission(payload);
      global.store.actions.alert.pushToast({
        type: "success",
        message: "Project Submitted Successfully!",
      });
      $loading.set(false);
    } catch (error: any) {
      $loading.set(false);
    }
  };

  return (
    <section class="w-full flex-col min-h-full h-full md:px-5 md:py-3 px-3 py-4 overflow-x-hidden  !overflow-y-auto">
      <div class="flex flex-row w-fit items-center xs:(space-x-2) <xs:(flex-wrap)">
        <h1 class="w-full  text-xl font-[600] text-black <md:(text-22px) xs:(whitespace-nowrap) ">
          Project Submission
        </h1>
        <a
          href={$settings().values?.url}
          download
          target="_blank"
          class="flex flex-row whitespace-nowrap w-fit h-fit text-center cursor-pointer py0.5 px1 text-0.5rem rounded-1 no-underline bg!a text#page"
        >
          Instructions PDF
        </a>
      </div>
      <Switch>
        <Match when={$submission_details.state.loading}>
          <div class="flex w-full min-h-screen md:h-full  items-center justify-center">
            <Loader2 msg="Loading Submission Details..." />
          </div>
        </Match>
        <Match when={$submission_details.state.failed}>
          an error occurred while fetching {$submission_details.error}
        </Match>

        <Match when={!$can_update.value}>
          <div class="w-full h-full flex flex-col justify-center gap-4 items-center">
            <h2 class="text#p font-bold text-1.2rem  text-center">
              You can not submit project because, you are not in a team or your team members did not reach the minimum
              (the min is 3 members)
            </h2>
            <div class="w-full flex justify-center items-center ">
              <PrimaryButton
                label="Create Team"
                classes="!bg#p !text-19px"
                onClick={() => global.store.actions.route.navigate("/participants")}
              />
            </div>
          </div>
        </Match>
        <Match when={$submission_details.state.success && $can_update.value}>
          <form class="w-full flex flex-col justify-center pb-13 pt-6 <md:(py-10)   ">
            {/* first row */}
            <div class="w-full flex flex-col md:flex-row justify-center items-start gap-2 ">
              <div class="w-full md:w-50%  py-2 ">
                <FormInputs.TeamName.Label title="Team Name" class="form-label "></FormInputs.TeamName.Label>
                <FormInputs.TeamName.Error class="form-input-error" />

                <FormInputs.TeamName.Input
                  autocomplete="Team Name"
                  type="text"
                  class="form-input"
                  placeholder={`Enter Team Name`}
                />
              </div>

              <div class="w-full md:w-50%  py-2">
                <FormInputs.Track.Label title="Team Track" class="form-label">
                  <FormInputs.Track.Error class="form-input-error" />
                </FormInputs.Track.Label>
                <FormInputs.Track.Input autocomplete="Track" class="form-input" />
              </div>
            </div>

            {/* Second row */}
            <div class="w-full flex flex-col md:flex-row justify-center items-center gap-2 ">
              <div class="w-full md:w-50%  py-3">
                <FormInputs.ProjectName.Label title="Project Name" class="form-label">
                  <FormInputs.ProjectName.Error class="form-input-error" />
                </FormInputs.ProjectName.Label>
                <FormInputs.ProjectName.Input
                  autocomplete="Project Name"
                  type="text"
                  class="form-input"
                  placeholder={`Enter Project Name`}
                />
              </div>

              <div class="w-full md:w-50%  py-3 space-y-2">
                <FormInputs.ProjectCover.Label title="Project Cover" class="form-label">
                  <FormInputs.ProjectCover.Error class="form-input-error" />
                </FormInputs.ProjectCover.Label>
                <FormInputs.ProjectCover.InputFile
                  placeholder="/images/profile_image_template.png"
                  accept="image/*"
                  classes={{
                    container:
                      "flex flex-col justify-center items-center bg#bg-light text#orange border-2 border#orange border-dashed text-14px px-2.5 py-5 my-[2px] rounded-9px w-full !text-0.7rem",
                  }}
                  elements={{
                    input({ onInputClicked, viewer_data, drag }) {
                      return (
                        <div
                          onclick={onInputClicked}
                          class="flex flex-col w-full h-full justify-center items-center gap-5px cursor-pointer"
                        >
                          <p class="cursor-pointer">
                            <Show when={!drag} fallback={"Drop Files"}>
                              <span class="underline text-16px">
                                Upload .PNG, JPG, JPEG file, less than {FormInputs.$$META.image_max} MB
                              </span>
                            </Show>
                          </p>
                          <Show when={viewer_data.file}>{viewer_data.file.name}</Show>
                        </div>
                      );
                    },
                  }}
                />
              </div>
            </div>

            {/* Third row */}
            <div class="w-full flex flex-col md:flex-row justify-center items-start gap-2 ">
              <div class="w-full md:w-50%  py-3 ">
                <FormInputs.ProblemStatement.Label title="Problem Statement" class="form-label">
                  <FormInputs.ProblemStatement.Error class="form-input-error" />
                </FormInputs.ProblemStatement.Label>

                <div class="relative">
                  <FormInputs.ProblemStatement.TextArea
                    autocomplete="Problem_Statement"
                    class="text-0.7!rem  min-h-140px  min-w-99% p-5px form-input px-3 py-2! mt-2 outline-none rounded-9px"
                    placeholder="Write a brief about the problem"
                    maxlength={char_limit}
                  />
                  <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">{$ProblemStatementChars()}</div>
                </div>
              </div>

              <div class="w-full md:w-50%  py-3">
                <FormInputs.ResearchProcess.Label title="Research Process" class="form-label">
                  <FormInputs.ResearchProcess.Error class="form-input-error" />
                </FormInputs.ResearchProcess.Label>

                <div class="relative">
                  <FormInputs.ResearchProcess.TextArea
                    autocomplete="Research_Process"
                    class="text-0.7!rem  min-h-140px  min-w-99% p-5px form-input px-3 py-2! mt-2 outline-none rounded-9px"
                    placeholder="Write a brief about the problem"
                    maxlength={char_limit}
                  />
                  <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">{$ResearchProcessChars()}</div>
                </div>
              </div>
            </div>

            {/* Fourth row */}
            <div class="w-full flex flex-col md:flex-row justify-center items-start gap-2 ">
              <div class="w-full md:w-50%  py-3 ">
                <FormInputs.Ideation.Label title="Ideation" class="form-label">
                  <FormInputs.Ideation.Error class="form-input-error" />
                </FormInputs.Ideation.Label>

                <div class="relative">
                  <FormInputs.Ideation.TextArea
                    autocomplete="Ideation"
                    class="text-0.7!rem  min-h-140px  min-w-99% p-5px form-input px-3 py-2! mt-2 outline-none rounded-9px"
                    placeholder="Write a brief about the idea with its objectives "
                    maxlength={char_limit}
                  />

                  <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">{$IdeationChars()}</div>
                </div>
              </div>

              <div class="w-full md:w-50%  py-3">
                <FormInputs.ApplicableSolution.Label title="Applicable Solution" class="form-label">
                  <FormInputs.ApplicableSolution.Error class="form-input-error" />
                </FormInputs.ApplicableSolution.Label>

                <div class="relative">
                  <FormInputs.ApplicableSolution.TextArea
                    autocomplete="Applicable_Solution"
                    class="text-0.7!rem  min-h-140px  min-w-99% p-5px form-input px-3 py-2! mt-2 outline-none rounded-9px"
                    placeholder="Write a brief about the problem"
                    maxlength={char_limit}
                  />
                  <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">
                    {$ApplicableSolutionChars()}
                  </div>
                </div>
              </div>
            </div>

            {/* Fivth row */}
            <div class="w-full flex flex-col md:flex-row justify-center items-start gap-2 ">
              <div class="w-full md:w-50%  py-3 ">
                <FormInputs.Feasibility.Label title="Solution Feasibility" class="form-label">
                  <FormInputs.Feasibility.Error class="form-input-error" />
                </FormInputs.Feasibility.Label>

                <div class="relative">
                  <FormInputs.Feasibility.TextArea
                    autocomplete="Solution Feasibility"
                    class="text-0.7!rem  min-h-140px  min-w-99% p-5px form-input px-3 py-2! mt-2 outline-none rounded-9px"
                    placeholder="Write a brief about the Feasibility Solution"
                    maxlength={char_limit}
                  />

                  <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">
                    {$SolutionFeasibilityChars()}
                  </div>
                </div>
              </div>

              <div class="w-full md:w-50%  py-3">
                <FormInputs.BusinessModel.Label title="Business Model" class="form-label">
                  <FormInputs.BusinessModel.Error class="form-input-error" />
                </FormInputs.BusinessModel.Label>

                <div class="relative">
                  <FormInputs.BusinessModel.TextArea
                    autocomplete="Business_Model"
                    class="text-0.7!rem  min-h-140px  min-w-99% p-5px form-input px-3 py-2! mt-2 outline-none rounded-9px"
                    placeholder="Write a brief about the problem"
                    maxlength={char_limit}
                  />
                  <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">{$BusinessModelChars()}</div>
                </div>
              </div>
            </div>

            {/* Sixth row */}
            <div class="w-full flex flex-col md:flex-row justify-center items-start gap-5 ">
              <div class="w-full md:w-50%  py-3 ">
                <FormInputs.Impact.Label title="Project Impact" class="form-label">
                  <FormInputs.Impact.Error class="form-input-error" />
                </FormInputs.Impact.Label>

                <div class="relative">
                  <FormInputs.Impact.TextArea
                    autocomplete="Project Impact"
                    class="text-0.7!rem  min-h-140px  min-w-99% p-5px form-input px-3 py-2! mt-2 outline-none rounded-9px"
                    placeholder="Write a brief about The impacts of your project "
                    maxlength={char_limit}
                  />

                  <div class="absolute right-0 bottom-[-25px] text-gray-300 text-15px">{$ProjectImpactChars()}</div>
                </div>
              </div>

              <div class="w-full md:w-50%  py-3">
                <FormInputs.ProjectVideo.Label title="Project Video" class="form-label">
                  <FormInputs.ProjectVideo.Error class="form-input-error" />
                </FormInputs.ProjectVideo.Label>

                <FormInputs.ProjectVideo.Input
                  autocomplete="Project Video"
                  type="text"
                  class="form-input px-3 py-4! md:py-5! outline-none rounded-9px  my-2 text-0.7rem! "
                  placeholder="https://..."
                />
              </div>
            </div>

            {/* Seventh row */}
            <div class="w-full flex flex-col md:flex-row justify-center items-start gap-5 ">
              <div class="w-full md:w-50%  py-3 space-y-2">
                <FormInputs.ProjectImages.Label title="Project Images" class="form-label">
                  <FormInputs.ProjectImages.Error class="form-input-error" />
                </FormInputs.ProjectImages.Label>
                <FormInputs.ProjectImages.InputFile
                  accept=".pdf"
                  classes={{
                    container:
                      "flex flex-col justify-center items-center bg#bg-light text#orange border-2 border#orange border-dashed text-14px px-2.5 py-5 my-[2px] rounded-9px w-full text-0.7rem!",
                  }}
                  elements={{
                    input({ onInputClicked, viewer_data, drag }) {
                      return (
                        <div
                          onclick={onInputClicked}
                          class="flex flex-col w-full h-full justify-center items-center gap-5px cursor-pointer"
                        >
                          <p class="cursor-pointer">
                            <Show when={!drag} fallback={"Drop Files"}>
                              <span class="underline text-16px">
                                Upload .PDF file, less than {FormInputs.$$META.pdf_max} MB
                              </span>
                            </Show>
                          </p>
                          <Show when={viewer_data.file}>{viewer_data.file.name}</Show>
                        </div>
                      );
                    },
                  }}
                />
                <p class="text-gray-500 text-0.5rem py-2">previous upload: {$submission_details.value?.image}</p>
              </div>

              <div class="w-full md:w-50%  py-3 space-y-2">
                <FormInputs.Presentation.Label title="Project Presentation" class="form-label">
                  <FormInputs.Presentation.Error class="form-input-error" />
                </FormInputs.Presentation.Label>
                <FormInputs.Presentation.InputFile
                  accept=".pdf"
                  classes={{
                    container:
                      "flex flex-col justify-center items-center bg#bg-light text#orange border-2 border#orange border-dashed text-14px px-2.5 py-5 my-[2px] rounded-9px w-full text-0.7rem!",
                  }}
                  elements={{
                    input({ onInputClicked, viewer_data, drag }) {
                      return (
                        <div
                          onclick={onInputClicked}
                          class="flex flex-col w-full h-full justify-center items-center gap-5px cursor-pointer"
                        >
                          <p class="cursor-pointer">
                            <Show when={!drag} fallback={"Drop Files"}>
                              <span class="underline text-16px">
                                Upload .PDF file, less than {FormInputs.$$META.pdf_max} MB
                              </span>
                            </Show>
                          </p>
                          <Show when={viewer_data.file}>{viewer_data.file.name}</Show>
                        </div>
                      );
                    },
                  }}
                />
                <p class="text-gray-500 text-0.5rem py-2">previous upload: {$submission_details.value?.presentation}</p>
              </div>
            </div>

            {/* submit btn */}
            <div class="flex justify-end">
              <Show
                when={!$loading.value}
                fallback={
                  <PrimaryButton label="Submitting Project..." classes="!px-30px  !py-[12px] !text-[16px]" disabled />
                }
              >
                <FormInputs.Actions.Button
                  class="disabled:bg#disabled text-white  flex items-center justify-center text-16px font-bold cursor-pointer bg#orange mt-7 mb-2  px-30px py-2 rounded-md"
                  onclick={(e) => {
                    global.store.actions.popop.pushConfirmDialog({
                      type: "warn",
                      message: "Are you sure you want to submit this project?",
                      events: {
                        onConfirmed() {
                          onSubmit(e);
                        },
                      },
                    });
                  }}
                  statusValid={"Submit Project"}
                  statusInvalid={"Submit Project"}
                />
              </Show>
            </div>
          </form>
        </Match>
      </Switch>
    </section>
  );
}
